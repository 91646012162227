var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "article",
    [
      _c("h1", { staticClass: "mb-3 text-3xl" }, [
        _vm._v(_vm._s(_vm.plan.name))
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "text-xl text-gray-800" }, [
        _vm._v(_vm._s(_vm.plan.description))
      ]),
      _vm._v(" "),
      _c("field-list", {
        attrs: { fields: _vm.fields },
        scopedSlots: _vm._u([
          {
            key: "extra-fields",
            fn: function() {
              return [
                _vm.plan.document
                  ? _c("field-item", {
                      attrs: { name: "Document", value: _vm.plan.document },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "value",
                            fn: function() {
                              return [
                                _c(
                                  "a",
                                  {
                                    staticClass: "border-b-2 border-current",
                                    attrs: { href: _vm.plan.document }
                                  },
                                  [_vm._v(_vm._s(_vm.plan.document))]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        3702781868
                      )
                    })
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm._v(" "),
      _c("h2", { staticClass: "mb-3 text-2xl" }, [
        _vm._v("Features of the plan")
      ]),
      _vm._v(" "),
      _vm.plan.features.length > 0
        ? _c(
            "ul",
            { staticClass: "list-none" },
            _vm._l(_vm.plan.features, function(feature) {
              return _c(
                "li",
                {
                  key: feature.id,
                  staticClass: "my-2 flex items-center justify-between"
                },
                [
                  _c("toggle", {
                    staticClass: "mr-2",
                    attrs: { id: feature.id, value: feature.enabled },
                    on: {
                      changed: function($event) {
                        return _vm.handleToggle(feature, $event)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass: "flex-1 pr-2",
                      attrs: { id: feature.id + "-label" }
                    },
                    [
                      _vm._v(
                        _vm._s(feature.type) + " " + _vm._s(feature.alignment)
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass:
                        "px-2 py-1 bg-blue-500 text-white text-sm rounded-md"
                    },
                    [_vm._v(_vm._s(feature.count))]
                  )
                ],
                1
              )
            }),
            0
          )
        : _c("span", { staticClass: "italic" }, [
            _vm._v("No features in this plan")
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }