var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.value
    ? _c(
        "div",
        { staticClass: "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 my-3" },
        [
          _c(
            "dt",
            { staticClass: "text-gray-700" },
            [_vm._t("name", [_vm._v(_vm._s(_vm.name))])],
            2
          ),
          _vm._v(" "),
          _c("dd", [_vm._t("value", [_vm._v(_vm._s(_vm.value))])], 2)
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }