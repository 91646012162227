var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "dl",
    [
      _vm._t(
        "default",
        _vm._l(_vm.fields, function(field, index) {
          return _c("field-item", {
            key: index,
            attrs: { name: field.name, value: field.value }
          })
        })
      ),
      _vm._v(" "),
      _vm._t("extra-fields")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }