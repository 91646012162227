var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "router-link",
    {
      staticClass:
        "block my-2 px-2 py-3 shadow border rounded bg-white hover:bg-blue-100 focus:bg-blue-100",
      attrs: {
        to: "" + _vm.plan.id,
        append: _vm.$route.params.id ? false : true
      },
      nativeOn: {
        mouseover: function($event) {
          return _vm.highlightPlan({ plan: _vm.plan })
        },
        focus: function($event) {
          return _vm.highlightPlan({ plan: _vm.plan })
        }
      }
    },
    [
      _vm._t("default", [
        _c("article", [
          _vm.showType
            ? _c(
                "header",
                {
                  staticClass:
                    "px-2 py-1 mb-2 bg-fog-300 text-fog-900 text-sm rounded-md shadow inline-block"
                },
                [_vm._v("\n        Master street plan\n      ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("h3", { staticClass: "text-lg" }, [_vm._v(_vm._s(_vm.plan.name))]),
          _vm._v(" "),
          _c("main", { staticClass: "text-sm text-gray-700" }, [
            _c("dl", [
              _c("div", { staticClass: "flex items-center my-1" }, [
                _c("dt", { staticClass: "mr-2" }, [
                  _c("i", {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.feather.icons["map-pin"].toSvg({ class: "w-4 h-4" })
                      )
                    }
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "sr-only" }, [
                    _vm._v("Feature count")
                  ])
                ]),
                _vm._v(" "),
                _c("dd", [
                  _c("span", [
                    _vm._v(_vm._s(_vm.plan.features.length) + " features")
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }