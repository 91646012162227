var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "p-1 flex items-center justify-between border-2 border-current rounded text-base"
    },
    [
      _c(
        "button",
        {
          attrs: {
            id: _vm.id,
            role: "switch",
            "aria-checked": String(_vm.value),
            "aria-labelledby": _vm.id + "-label"
          },
          on: { click: _vm.toggle }
        },
        [
          _c("span", { staticClass: "px-1 rounded" }, [_vm._v("on")]),
          _vm._v(" "),
          _c("span", { staticClass: "px-1 rounded" }, [_vm._v("off")])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }